export const CDN_RESIZE_ENDPOINT = 'cdn-cgi/image' as const
const CDN_DOMAIN_WITH_DNS_PROXY =
  process.env.NODE_ENV === 'production'
    ? ('https://public.wordup.com.tw' as const)
    : ('https://public-staging.wordup.com.tw' as const)

type ImageLoaderParams = { src: string; width: number; quality?: number }
const cloudflareLoader = ({ src, width }: ImageLoaderParams) => {
  const CDN_RESIZE_CONFIG = [`width=${width}`, 'format=auto'].join(',')
  return [CDN_DOMAIN_WITH_DNS_PROXY, CDN_RESIZE_ENDPOINT, CDN_RESIZE_CONFIG, src].join('/')
}

export default cloudflareLoader

// FIXME: After default loader is set. CDN use CDN loader, otherwise use default loader. If we still want to use both of resource for resize image.
// const IS_CDN_DOMAIN_WITH_DNS_PROXY_REGEX = /^https:\/\/public(-staging)?\.wordup\.com\.tw/gi
// export default (src: string) =>
//   IS_CDN_DOMAIN_WITH_DNS_PROXY_REGEX.test(src) ? cloudflareLoader : undefined
