import NextImage from 'next/image'
import { twMerge } from 'tailwind-merge'
import { EMPTY_IMAGE_FALLBACK } from './constants'
import customLoader from './custom-loader'
import getImageUri from './get-image-uri'

type Props = React.ComponentProps<typeof NextImage> & {
  fallbackSrc?: string
}

const Image = ({ src, fallbackSrc = EMPTY_IMAGE_FALLBACK, className, ...props }: Props) => {
  // @ts-expect-error -- url is always string currently
  const imgUrl = getImageUri(src) || fallbackSrc

  return (
    <NextImage
      {...props}
      src={imgUrl}
      className={twMerge('max-w-full', className)}
      loader={customLoader}
    />
  )
}

export { Image }
