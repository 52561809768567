import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { formatMoney } from '../../utils/formatter'
import { isLocaleTW } from '../../utils/i18n'
import { Text, type TextPropsTypes } from '../text'
import { convertFractionToChineseDiscount, convertFractionToPercentage } from './utils'

type Props = {
  variant?: TextPropsTypes['variant']
  className?: string
  parentheses?: boolean
  color?: TextPropsTypes['color']
} & (
  | { listPrice: string | number; salePrice: string | number }
  | { couponDiscountFraction: number }
)

const DiscountText = ({
  variant = 'label_s_400',
  className,
  color,
  parentheses,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation()
  const text = useMemo(() => {
    const getDiscountFractionText = (discountFraction: number) => {
      const inText = isLocaleTW(i18n.resolvedLanguage as string)
        ? convertFractionToChineseDiscount(discountFraction)
        : convertFractionToPercentage(discountFraction)
      return inText ? t('crowdin:common.discount-percentage-text', { percentage: inText }) : ''
    }

    if ('couponDiscountFraction' in props) {
      return getDiscountFractionText(props.couponDiscountFraction)
    }

    const { listPrice, salePrice } = props
    const discountAmount = +listPrice - +salePrice
    if (discountAmount > +t('crowdin:product.show-discount-amount-threshold')) {
      return t('crowdin:common.discount-amount-text', { amount: formatMoney(discountAmount) })
    }
    const discountRatio = getDiscountFractionText(+salePrice / +listPrice)
    return discountRatio
  }, [props, i18n, t])

  if (!text) return null

  return (
    <Text variant={variant} color={color} className={twMerge('whitespace-nowrap', className)}>
      {parentheses ? `(${text})` : text}
    </Text>
  )
}

export { DiscountText }
