import type { ProductType } from '../../../../../types/product'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { SHOP_PATH } from '../../../../../utils/routes'
import { Text } from '../../../../text'

const Publisher = ({
  materialId,
  attributes: { name, authorName, publisherName },
  isHorizontal,
}: {
  materialId: string
  attributes: ProductType.Data['attributes']
  isHorizontal?: boolean
}) => {
  return (
    <Link
      data-testid='product-card-publisher'
      href={SHOP_PATH.DETAIL({ productId: materialId })}
      className='flex flex-col gap-2 no-underline'
    >
      <Text
        variant='body_m_500'
        className={twMerge('clamp-line-2 block', !isHorizontal && 'min-h-[56px]')}
      >
        {name}
      </Text>

      <Text
        variant='label_s_400'
        color='grayscale-500'
        className={twMerge(
          'clamp-line-1 block',
          isHorizontal ? '!font-medium' : 'min-h-[21px]',
          !authorName && !publisherName && 'opacity-0',
        )}
      >
        by {authorName}
        {authorName && publisherName && '・'}
        {publisherName}
      </Text>
    </Link>
  )
}
export default Publisher
