export const convertFractionToChineseDiscount = (discountFraction: number) => {
  if (discountFraction >= 1 || discountFraction < 0 || isNaN(discountFraction)) {
    return ''
  }

  const discount = Math.round(discountFraction * 100).toString()

  if (discount.length === 1) {
    return `0.${discount}`
  } else {
    const [tens, units] = discount.split('')

    if (units === '0' || !units) {
      return `${tens}`
    } else {
      return `${tens}${units}`
    }
  }
}

export const convertFractionToPercentage = (discountFraction: number) => {
  if (discountFraction >= 1 || discountFraction < 0 || isNaN(discountFraction)) {
    return ''
  }

  return `${Math.round((1 - discountFraction) * 100)}`
}
